import OPEN_GUMIGPS_CONFIG from "./OpenGumiGPSConfig";

const config = OPEN_GUMIGPS_CONFIG;

const API_BASE_URL = config.apiBaseUrl;

const LOGIN_KEY = config.loginKey;

const API_ID = config.apiId;

export { API_BASE_URL, LOGIN_KEY, API_ID }