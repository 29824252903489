import axios, { Method } from 'axios';
import { authenticate } from './authentication';
import AsyncStorage from '../storage-helper/AsyncStorage';

export async function makeRequest(url: string, method: Method, data = {}): Promise<any> {

    const accessToken = await AsyncStorage.getItem("AccessToken");
    const AccessTokenExpiresIn = await AsyncStorage.getItem("AccessTokenExpiresIn");

    try {

        if (Date.now() > AccessTokenExpiresIn) {
            await authenticate();
        }

        let urlCombined = '';

        if (accessToken == null) {
            urlCombined = `${url}`;
        } else {
            urlCombined = `${url}?accessToken=${accessToken}`;
        }

        const params = Object.entries(data)
            .map(([key, value]) => `${key}=${value}`)
            .join('&');

        if (params) {
            urlCombined = `${urlCombined}&${params}`;
        }

        const response = await axios({
            method,
            url: urlCombined,
        });

        return response?.data;

    } catch (error: any) {
        // Hata mesajlarını birleştirmek için bir değişken oluştur
        let errorMessage = "Bir hata oluştu.";

        // error.response varsa hata bilgilerini kontrol et
        if (error.response) {
            errorMessage = `Status: ${error.response.status}\n`;
            errorMessage += `Data: ${JSON.stringify(error.response.data, null, 2)}\n`;
            errorMessage += `Headers: ${JSON.stringify(error.response.headers, null, 2)}`;

        } else {
            // Eğer response yoksa genel bir hata mesajı göster
            errorMessage = error.message || "Bilinmeyen bir hata oluştu.";
        }

        // Hata fırlatma (gerekirse bu kısmı kaldırabilirsiniz)
        throw errorMessage;
    }
}
