import CryptoJS from "crypto-js";
import { API_BASE_URL, API_ID, LOGIN_KEY } from './config';
import axios from "axios";
import { toNumber } from "lodash";
import AsyncStorage from "../storage-helper/AsyncStorage";

export async function authenticate(): Promise<void> {

    const AccessTokenExpiresIn = await AsyncStorage.getItem("AccessTokenExpiresIn");
    const expiresIn = toNumber(AccessTokenExpiresIn);

    if (!isNaN(expiresIn) && expiresIn > Date.now()) {
        console.log('Access token is still valid.');
        return;
    }

    const url = `${API_BASE_URL}/auth`;
    const currentTime = Math.floor(Date.now() / 1000);

    const generateSignature = (key: string, time: number): string => {
        const firstMd5 = CryptoJS.MD5(key);
        const message = firstMd5.toString(CryptoJS.enc.Base64) + time;
        const signature = CryptoJS.MD5(message);
        return signature.toString();
    };

    const signature = generateSignature(LOGIN_KEY, currentTime);
    const requestBody = {
        appid: API_ID,
        time: currentTime,
        signature: signature,
    };

    try {
        const response = await axios.post(url, requestBody);
        const resultObject = response.data;

        await AsyncStorage.setItem("AccessToken", resultObject.accessToken);
        const expiresIn = Date.now() + (resultObject.expiresIn * 1000); // ms cinsine çevir

        await AsyncStorage.setItem("AccessTokenExpiresIn", expiresIn.toString());
        console.log('Authentication Successful');
    } catch (error: any) {
        console.error('Authentication Failed:', error.message || error);
    }
}

export { API_BASE_URL };
