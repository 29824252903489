
import { useContext, useState } from "react";
import './../i18n'
import {
  Alert,
  Button,
  Card,
  Col,
  Descriptions,
  Drawer,
  List,
  message,
  Modal,
  Row,
  Space,
} from "antd";
import Paragraph from "antd/lib/typography/Paragraph";

import React from "react";
import ActiveShuttleInfo from "../components/report/activeShuttle";
import TrackingMapApp from "../components/Map/TrackingMapApp";
import CarContext from "../Context/CarContext";
import { Car } from "../model/Car";
import { CarOutlined, CloseOutlined, HistoryOutlined, InfoCircleOutlined, PauseOutlined, SettingOutlined, StopOutlined } from "@ant-design/icons";
import Title from "antd/lib/typography/Title";
import { DeviceHistoryTrack } from "../model/DeviceHistoryTrack";
import { getDeviceHistoryTrack } from "../api/device";

function TrackingDashboard() {
  const contentList = {
    tab1: <ActiveShuttleInfo></ActiveShuttleInfo>,
    tab2: <p>Arac Listesi</p>,
  };
  const [carItems, setCarItems] = useContext(CarContext);
  const [activeTab, setActiveTab] = useState('tab1' as string);
  const [selectedCar, setSelectedCar] = useState(null as unknown as Car);
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);
  const [activeCarItems, setActiveCarItems] = useState([] as Car[]);
  const [followSelectedCar, setFollowSelectedCar] = useState(false);
  const [deviceHistoryRouteCoordinates, setDeviceHistoryRouteCoordinates] = useState([] as DeviceHistoryTrack[]);
  const getYesterday = () => {
    const today = new Date();
    today.setDate(today.getDate() - 1);
    return today;
  }
  const [startDate, setStartDate] = useState(getYesterday());
  const [endDate, setEndDate] = useState(new Date());

  const carImeiActivated = true; // Örnek olarak bu değeri true kabul ettik
  const watchHistoryTrack = false; // Örnek olarak bu değeri false kabul ettik
  const texts = {
    follow: 'Takip Et',
    unfollow: 'Takipten Çık',
    trackHistory: 'Geçmişi İzle',
    stopTracking: 'Takibi Durdur',
    settings: 'Ayarlar',
    offlineMessage: 'Araç çevrimdışı',
  };

  const handleCarClick = (car: any) => {
    setSelectedCar(car);
    setIsDrawerVisible(true);
  };

  const handleDrawerClose = () => {
    setIsDrawerVisible(false);
  };


  const handleTabChange = (key: React.SetStateAction<string>) => {
    setActiveTab(key);
  };

  const tabList = [
    {
      key: 'tab1',
      tab: 'Hareket Halindeki'
    },
    {
      key: 'tab2',
      tab: 'Tümü'
    }
  ];

  const handleOnPressFollowSelectedCar = () => {
    setFollowSelectedCar(!followSelectedCar);
    setIsDrawerVisible(false);
  }

  const handleOnPressRouteHistory = () => {

    if (deviceHistoryRouteCoordinates.length > 0) {
      setDeviceHistoryRouteCoordinates([] as DeviceHistoryTrack[]);
      return;
    }

    const startOfDayUnixTime = Math.floor(startDate.getTime() / 1000);
    const endOfDayUnixTime = Math.floor(endDate.getTime() / 1000);

    // setLoading(true);

    getDeviceHistoryTrack(selectedCar.IMEI?.toString(),
      startOfDayUnixTime.toString(),
      endOfDayUnixTime.toString(),
      'wgs84', '0')
      .then((result: any) => {
        if (result && result['data'] && result['data'].length > 0) {
          setDeviceHistoryRouteCoordinates(result['data'] as DeviceHistoryTrack[]);
          console.log(result['data'])
        } else {
          message.success({
            content: 'Gecmis rota bulunamadi!',
            style: {
              zIndex: "999999999999999",
              marginTop: '200vh',
            },
          });
        }
      })
    // .finally(() =>
    //   setLoading(false)
    // )
  }

  const TabListComponent = ({ items }: any) => {

    if (items.length === 0) {
      return <Card style={{ textAlign: 'center', marginTop: '20px' }}>
        <Row justify="center">
          <Col>
            <StopOutlined style={{ fontSize: '60px', color: '#FF6347', marginBottom: '20px', transform: 'rotate(90deg)' }} />
          </Col>
        </Row>
        <Row justify="center" style={{ marginTop: '20px' }}>
          <Col>
            <Title level={4} style={{ color: '#8c8c8c' }}>
              Bütün araçlar park halinde.
            </Title>
          </Col>
        </Row>
      </Card>;
    }

    return <List
      dataSource={items}
      renderItem={(car: Car) => (
        <List.Item onClick={() => handleCarClick(car)}>
          <List.Item.Meta
            avatar={<CarOutlined style={{ fontSize: '24px', color: '#1890ff' }} />}
            title={car.CarNumberPlate}
            description={`Yolcu Sayısı: ${car.PassengerCount}, Status: ${car.Status}`}
          />
        </List.Item>
      )}
    />
  }

  return (
    <>
      <Drawer
        title={"Arac Detaylari"}
        placement="bottom"
        closable={true}
        onClose={handleDrawerClose}
        visible={isDrawerVisible}
        getContainer={false}
        maskClosable={true}
        closeIcon={<CloseOutlined style={{ position: "absolute", right: 16, top: 16 }} />}
        maskStyle={{ backgroundColor: "rgba(0, 0, 0, 0)" }}
      >
        <Row style={{ textAlign: "center", justifyContent: "center" }}>
          <Space>
            <Button
              onClick={() => (carImeiActivated ? handleOnPressFollowSelectedCar() : alert(texts.offlineMessage))}
              icon={<CarOutlined style={{ fontSize: 16, color: '#2196F3' }} />}
              size="small"
              style={{ width: 90 }}
            >
              <p style={{ fontSize: 12 }}>{followSelectedCar ? texts.unfollow : texts.follow}</p>
            </Button>
            <Button
              icon={<HistoryOutlined style={{ fontSize: 16, color: '#FF9800' }} />}
              size="small"
              style={{ width: 90 }}
              onClick={handleOnPressRouteHistory}
            >

              <p style={{ fontSize: 12 }}>{watchHistoryTrack ? texts.stopTracking : texts.trackHistory}</p>
            </Button>
            <Button
              icon={<SettingOutlined style={{ fontSize: 16, color: '#4CAF50' }} />}
              size="small"
              style={{ width: 90 }}
            >
              <p style={{ fontSize: 12 }}>{texts.settings}</p>
            </Button>
          </Space>
        </Row>
        {selectedCar && (

          <Card
            style={{ marginTop: '20px', borderRadius: '10px', boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)' }}
          >
            <Descriptions layout="vertical" bordered>
              <Descriptions.Item label="Araç Plakası">{selectedCar.CarNumberPlate}</Descriptions.Item>
              <Descriptions.Item label="Yolcu Sayısı">{selectedCar.PassengerCount}</Descriptions.Item>
              <Descriptions.Item label="Muayene Tarihi">{selectedCar.VehicleInspectionDate}</Descriptions.Item>
              <Descriptions.Item label="Sigorta Tarihi">{selectedCar.InsuranceDate}</Descriptions.Item>
              <Descriptions.Item label="KM">{selectedCar.KM}</Descriptions.Item>
              <Descriptions.Item label="Tanım">{selectedCar.Description}</Descriptions.Item>
            </Descriptions>
          </Card>

        )}
      </Drawer>
      <Row gutter={[24, 0]}>
        <Col xs={24} sm={24} md={12} lg={12} xl={16} className="mb-24">
          <Card bordered={false} className="criclebox cardbody h-full">
            <div className="project-ant">
              <div>
                <Paragraph className="lastweek">
                </Paragraph>
              </div>
            </div>

            <TrackingMapApp watchShuttleBus={false} setActiveCarItems={setActiveCarItems} followSelectedCar={followSelectedCar} selectedCar={selectedCar}></TrackingMapApp>

          </Card>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={8} className="mb-24" >

          <Card
            tabList={tabList}
            activeTabKey={activeTab}
            onTabChange={handleTabChange}
            title={'Araçlar'}
          >
            {{
              tab1: (<TabListComponent items={activeCarItems} />),
              tab2: (<TabListComponent items={carItems} />),
            }[activeTab]}
          </Card>
        </Col>
      </Row>
    </>
  );
}

export default TrackingDashboard;
