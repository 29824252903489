const AsyncStorage = {
    getItem: async (key: string) => {
        try {
            const value = localStorage.getItem(key);
            return value ? JSON.parse(value) : null;
        } catch (error) {
            console.error("Error getting item", error);
            throw error;
        }
    },
    setItem: async (key: string, value: any) => {
        try {
            localStorage.setItem(key, JSON.stringify(value));
        } catch (error) {
            console.error("Error setting item", error);
            throw error;
        }
    },
    removeItem: async (key: string) => {
        try {
            localStorage.removeItem(key);
        } catch (error) {
            console.error("Error removing item", error);
            throw error;
        }
    },
    clear: async () => {
        try {
            localStorage.clear();
        } catch (error) {
            console.error("Error clearing storage", error);
            throw error;
        }
    },
};

export default AsyncStorage;
