import { makeRequest } from './api';
import { API_BASE_URL } from './authentication';
import { User } from '../model/User';

export async function getMileageStatistics(imei: string, startTime: number): Promise<void> {
    const url = `${API_BASE_URL}/device/miles`;
    const params = {
        imei,
        startTime,
    };

    try {
        const result = await makeRequest(url, 'GET', params);
        console.log('Mileage Statistics');
        return result;
    } catch (error) {
        console.error('Failed to Fetch Mileage Statistics');
        console.error(error);
    }
}

export async function getDeviceStatusStatistics(account: string): Promise<void> {
    const url = `${API_BASE_URL}/device/status/statistic`;
    const params = {
        account,
    };

    try {
        const result = await makeRequest(url, 'GET', params);
        console.log('Device Status Statistics');
        console.log(result);
    } catch (error) {
        console.error('Failed to Fetch Device Status Statistics');
        console.error(error);
    }
}

export async function queryDeviceStatus(imei: string, account = '', selectedUser: User) {
    const url = `${API_BASE_URL}/device/status`;

    const params = {
        imei: imei,
        account: account,
    };

    try {
        const result = await makeRequest(url, 'GET', params);
        return result;
    } catch (error: any) {
        return undefined;
    }
}

export async function getDeviceHistoryTrack(imei: string, startTime: string, endTime: string, coordType: string, onlyGps: string) {
    console.log("test")
    const url = `${API_BASE_URL}/device/track/history`;
    const params = {
        imei,
        startTime,
        endTime,
        coordType,
        onlyGps,
    };

    try {
        const result = await makeRequest(url, 'GET', params);
        console.log('Device History Track Retrieved Successfully');
        return result;
    } catch (error) {
        console.error('Failed to Retrieve Device History Track');
        console.error(error);
    }
}

export async function batchCreateOrModifyDevices(deviceData: any) {
    const url = `${API_BASE_URL}/device`;
    const data = {
        data: deviceData,
    };

    try {
        const result = await makeRequest(url, 'POST', data);
        console.log('Batch Create/Modify Devices Successful');
        console.log(result);
    } catch (error) {
        console.error('Failed to Batch Create/Modify Devices');
        console.error(error);
    }
}