
import { Alert, Avatar, Button, Checkbox, Form, Input, List, Popconfirm, Row, Select, Space, Table, TimePicker } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { getTableItems, updateItemOnDb } from '../../dto/ServerHelper';
import { Col, Spin } from 'antd';
import { BranchesOutlined, DeleteOutlined, DribbbleCircleFilled, EditOutlined, InfoCircleOutlined, LikeOutlined, MessageOutlined, PlusOutlined, StarOutlined } from '@ant-design/icons';
import { handlePopup } from '../../redux/actions/actions';
import { Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { MarkerCoordinate } from '../../model/MarkerCoordinate';
import { Shuttle } from '../../model/Shuttle';
import FHShuttleDetail from './FHShuttleDetail';
import { School } from '../../model/School';
import { Car } from '../../model/Car';
import { Driver } from '../../model/Driver';
import UserContext from '../../Context/User';
import { User } from '../../model/User';
import _ from 'lodash';
import moment from 'moment';

const { Option } = Select;

interface FHShuttleTableProps {
    id?: any;
    handlePopup: any;
    onClickListItem?: any;
    filter?: any;
    passengerCoordinate?: MarkerCoordinate;
}

function FHShuttleTable(props: FHShuttleTableProps) {
    const [form] = Form.useForm();
    const { t } = useTranslation();
    const [data, setData] = useState([] as any[]);
    const [shuttleDirection, setShuttleDirection] = useState("Gidiş" as string);
    const [schoolData, setSchoolData] = useState([] as any[]);
    const [driverData, setDriverData] = useState([] as any[]);
    const [drivers, setDrivers] = useState([] as Driver[]);
    const [carData, setCarData] = useState([] as any[]);
    const [editItem, setEditItem] = useState({} as Shuttle);
    const [spinTip, setSpinTip] = useState("Loading...");
    const [selectedShuttle, setSelectedShuttle] = useState(undefined as unknown as Shuttle);
    const [user, setUser] = useContext(UserContext);
    console.log(`user --------------- ${user}`)
    const TABLE_NAME = "Shuttle";

    const columns = [
        { "title": t("OrganisationName"), "dataIndex": "OrganisationName", "key": "OrganisationName" },
        { "title": t("Organisation"), "dataIndex": "Organisation", "key": "Organisation" },
        { "title": t("ShuttleName"), "dataIndex": "ShuttleName", "key": "ShuttleName" },
        { "title": t("ShuttleTime"), "dataIndex": "ShuttleTime", "key": "ShuttleTime" },
        { "title": t("AvaregeTime"), "dataIndex": "AvaregeTime", "key": "AvaregeTime" },
        { "title": t("CarNumberPlate"), "dataIndex": "CarNumberPlate", "key": "CarNumberPlate" },
        { "title": t("Direction"), "dataIndex": "Direction", "key": "Direction" },
        { "title": t("DriverId"), "dataIndex": "DriverId", "key": "DriverId" },
        { "title": t("DriverName"), "dataIndex": "DriverName", "key": "DriverName" },
        { "title": t("Remember"), "dataIndex": "Remember", "key": "Remember" },
        { "title": t("Route"), "dataIndex": "Route", "key": "Route" },
        { "title": t("Status"), "dataIndex": "Status", "key": "Status" },
        { "title": t("Id"), "dataIndex": "Id", "key": "Id" },
        { "title": t("Passengers"), "dataIndex": "Passengers", "key": "Passengers" },
        {
            title: t('Action'),
            dataIndex: '',
            key: 'x',
            render: (_: any, record: any) =>
                <>
                    <Space>
                        {<Popconfirm title="Silmek istediğinizden emin misiniz?" onConfirm={() => { record.Status = "D"; saveChanges(record); }}>
                            <a>Sil</a>
                        </Popconfirm>}
                        {<a onClick={() => setEditItem(record)}>Güncelle</a>}
                        {<a onClick={() => setSelectedShuttle(record as Shuttle)}>Detay Bilgi</a>}
                    </Space>
                </>
        }
    ] as any;

    useEffect(() => {
        getTableItems(TABLE_NAME).then((result: any[]) => {
            const items = result.filter((item) => {
                let isEvery = true;
                if (props.filter) {
                    isEvery = Object.keys(props.filter).every((filterItem: string) => {
                        return props.filter[filterItem] === item[filterItem]
                    });
                }

                return item.Status !== "D" && isEvery && item.Organisation === (user as User).Organization.toString();
            });

            setData(items);

            setSpinTip("");
        });

        getTableItems("School").then((result: School[]) => {
            setSchoolData(result.filter((item) => { return item.Status !== "D" && item.Id === (user as User).Organization.toString(); }).map((schoolItem: School) => {
                return <Option key={schoolItem.Id} value={schoolItem.Id} >{schoolItem.Name}</Option>
            }));
        });

        getTableItems("Car").then((result: any[]) => {
            setCarData(result.filter((item) => { return item.Status !== "D"; }).map((carItem: Car) => {
                return <Option key={carItem.Id} value={carItem.Id}>{carItem.CarNumberPlate}</Option>
            }));
        });

        getTableItems("Driver").then((result: Driver[]) => {
            setDrivers(result);
            setDriverData(result.filter((item) => { return item.Status !== "D" && item.Organisation === (user as User).Organization.toString(); }).map((driverItem: Driver) => {
                return <Option key={driverItem.Id} value={driverItem.Id} carId={driverItem.CarId}>{`${driverItem.Name} ${driverItem.Surname}`}</Option>
            }));
        });

    }, []);

    useEffect(() => {
        form.setFieldsValue(editItem);
    }, [editItem]);

    const saveChanges = (changedItem: any) => {
        changedItem.Passengers = editItem.Passengers == undefined ? "" : editItem.Passengers;
        changedItem.ShuttleTime = changedItem.ShuttleTime.format('HH:mm');
        changedItem.AvaregeTime = changedItem.AvaregeTime.format('HH:mm');
        changedItem.Remember = changedItem.Remember.format('HH:mm');

        if (isExistingItem(changedItem.Id)) {
            updateExistingItem(changedItem);
        } else {
            addNewItem(changedItem);
        }

        cleanUpChangedItem(changedItem);

        updateItemOnDb(TABLE_NAME, changedItem, changedItem.Id);
        setEditItem({} as Shuttle);
    };

    const isExistingItem = (id: any) => {
        return data.some((item: any) => item.Id === id);
    };

    const updateExistingItem = (changedItem: any) => {
        const updatedData = data.map(item =>
            item.Id === changedItem.Id ? changedItem : item
        );
        setData(updatedData);
    };

    const addNewItem = (changedItem: Shuttle) => {
        changedItem.Id = (changedItem.ShuttleName)
            .replace(/\s+/g, '')
            .toUpperCase();

        const randomThreeDigits = Math.floor(100 + Math.random() * 900);
        changedItem.Id += randomThreeDigits;

        changedItem.Status = "N";
        setData(state => [...state, changedItem]);
    };

    const cleanUpChangedItem = (changedItem: any) => {
        delete changedItem["OrganisationName"];
        delete changedItem["DriverName"];
    };


    const handleShuttleDirectionChange = (value: string) => {
        setShuttleDirection(value)
    };

    const headerContent = <>
        <Row>
            <Col span={23}>
                <Select defaultValue="Gidiş" style={{ width: 120 }} onChange={handleShuttleDirectionChange}>
                    <Option value="Gidiş">Gidiş</Option>
                    <Option value="Dönüş">Dönüş</Option>
                </Select>
            </Col>
            <Col span={1}>
                <Button shape="round" icon={<PlusOutlined />}
                    onClick={() => { setEditItem({ Id: -1, Latitude: 36.1492181874959, Longitude: 36.191523848423586 } as any); form.resetFields(); }} />
            </Col>
        </Row>
    </>;

    const getDataSource = () => {
        if (data) {
            const result = data.filter((item: any) => { return (item.Status !== "D" && item.Direction === shuttleDirection) })
                .map((item) => {
                    if (schoolData.length > 0) {
                        const schoolInfo = schoolData.find((schoolItem: any) => { return schoolItem.props.value == item.Organisation });
                        item["OrganisationName"] = schoolInfo ? schoolInfo.props.children : "";
                    }
                    return item;
                })
                .map((item) => {
                    if (driverData.length > 0) {
                        const driverInfo = driverData.find((driverItem: any) => { return driverItem.props.value == item.DriverId });
                        item["DriverName"] = driverInfo ? driverInfo.props.children : "";


                        if (carData.length > 0) {
                            const carInfo = carData.find((carItem: any) => { return carItem.props.value == driverInfo.props.carId });
                            item["CarNumberPlate"] = carInfo ? carInfo.props.children : "";
                        }

                    }
                    return item;
                })

            return _.orderBy(result, ['Direction', 'ShuttleName'],
                ['asc']);
        }
        return [];
    }

    const onFinish = (values: any) => {
        console.log('Received values from form: ', values);
        saveChanges(values);
    }

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    const dataSource = getDataSource();

    const tableContent = <Col xs={24} sm={24} md={24} lg={24} xl={24}>
        <List
            itemLayout="horizontal"
            size="small"
            dataSource={dataSource}
            header={headerContent}
            footer={<></>}
            renderItem={item => (
                <List.Item
                    key={item.ShuttleName}
                    actions={[
                        <Popconfirm key={"acdk"} title="Silmek istediğinizden emin misiniz?" onConfirm={() => { item.Status = "D"; saveChanges(item); }}>
                            <Space>
                                <DeleteOutlined></DeleteOutlined>
                                <a style={{ color: "black" }} key="delete" >{"Sil"}</a>
                            </Space>
                        </Popconfirm>,
                        <Space key={"acdfe"}>
                            <EditOutlined></EditOutlined>
                            <a style={{ color: "black" }} onClick={() => {
                                setEditItem(item)

                            }} key="list-vertical-like-o" >{"Güncelle"}</a>
                        </Space>,
                        <Space key={"acdge"}>
                            <InfoCircleOutlined></InfoCircleOutlined>
                            <a style={{ color: "black" }} onClick={() => { setSelectedShuttle(item as Shuttle) }} key="list-vertical-message" >{"Öğrenci Bilgileri"}</a>
                        </Space>
                    ]}
                >
                    <List.Item.Meta
                        style={{ minWidth: 200, marginTop: 20, marginBottom: 20 }}
                        avatar={<Avatar icon={<DribbbleCircleFilled />} />}
                        title={`${item.ShuttleName} - ${item.Direction} - ${item.ShuttleTime}`}
                        description={
                            <>
                                <Row>
                                    <Col>
                                        {`${item.OrganisationName}`}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        {`${item.DriverName} | ${item.CarNumberPlate} | ${item.AvaregeTime}`}
                                    </Col>
                                </Row>
                            </>
                        }

                    />
                </List.Item>
            )}
        />


        {/* <Table
            rowKey="Id"
            scroll={{ x: true }}
            title={headerContent}
            columns={columns}
            expandable={{
                expandedRowRender: record => expandedRowRender(record),
                rowExpandable: record => record.name !== 'Not Expandable',
            }}
            dataSource={getDataSource()}
        /> */}
    </Col>;

    const formContent = (
        <Col xs={22} sm={22} md={22} lg={10} xl={10}>
            <Form
                form={form}
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                layout="horizontal"
                size={"middle"}
                initialValues={{}}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
            >
                <Form.Item
                    name={"ShuttleName"}
                    label={t("ShuttleName")}
                    rules={[{ required: true, message: 'Lütfen eksik alanları doldurunuz!' }]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    name={"ShuttleTime"}
                    label={t("ShuttleTime")}
                    rules={[{ required: true, message: 'Lütfen eksik alanları doldurunuz!' }]}
                >
                    <TimePicker
                        format={'HH:mm'}
                        style={{ width: '100%' }}
                    />
                </Form.Item>

                <Form.Item
                    name={"Route"}
                    label={t("Route")}
                    rules={[{ required: true, message: 'Lütfen eksik alanları doldurunuz!' }]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    name={"Direction"}
                    label={t("Direction")}
                    rules={[{ required: true, message: 'Lütfen eksik alanları doldurunuz!' }]}
                >
                    <Select>
                        <Option value="Gidiş">Gidiş</Option>
                        <Option value="Dönüş">Dönüş</Option>
                    </Select>
                </Form.Item>

                {/* <Form.Item
                    name={"AvaregeTime"}
                    label={t("AvaregeTime")}
                    rules={[{ required: true, message: 'Lütfen eksik alanları doldurunuz!' }]}
                >
                    <TimePicker
                        format={'HH:mm'}
                        style={{ width: '100%' }}
                    />
                </Form.Item> */}

                <Form.Item
                    name={"DriverId"}
                    label={t("DriverId")}
                    rules={[{ required: true, message: 'Lütfen eksik alanları doldurunuz!' }]}
                >
                    <Select placeholder="Okulu Seçiniz">
                        {driverData}
                    </Select>
                </Form.Item>

                {/* <Form.Item
                    name={"Remember"}
                    label={t("Remember")}
                    rules={[{ required: true, message: 'Lütfen eksik alanları doldurunuz!' }]}
                >
                    <TimePicker
                        format={'HH:mm'}
                        style={{ width: '100%' }}
                    />
                </Form.Item> */}

                <Form.Item
                    name={"Organisation"}
                    label={t("Organisation")}
                    rules={[{ required: true, message: 'Lütfen eksik alanları doldurunuz!' }]}
                >
                    <Select placeholder="Okulu Seçiniz">
                        {schoolData}
                    </Select>
                </Form.Item>

                <Form.Item name={"Status"} label={t("Status")} hidden={true}>
                    <Input />
                </Form.Item>

                <Form.Item name={"Id"} label={t("Id")} hidden={true}>
                    <Input />
                </Form.Item>

                <Form.Item>
                    <Row justify="end">
                        <Col>
                            <Button
                                htmlType="button"
                                style={{ marginRight: '10px', minWidth: "100px" }}
                                onClick={() => { setEditItem({} as Shuttle) }}
                            >
                                {t("Cancel")}
                            </Button>
                        </Col>
                        <Col>
                            <Button
                                type="primary"
                                htmlType="submit"
                                style={{ minWidth: "100px" }}
                            >
                                {t("Save")}
                            </Button>
                        </Col>
                    </Row>
                </Form.Item>
            </Form>
        </Col>
    );


    return (
        <>
            {selectedShuttle
                ? <FHShuttleDetail shuttle={selectedShuttle} setSelectedShuttle={setSelectedShuttle} driverInfo={drivers.find((driverItem: Driver) => { return driverItem.Id == selectedShuttle.DriverId }) as Driver}></FHShuttleDetail>
                : <Spin spinning={spinTip !== ""} tip={spinTip}>
                    <Row justify="center" align="middle">
                        {
                            editItem.Id
                                ? formContent
                                : tableContent
                        }
                    </Row>
                </Spin>
            }
        </>
    );
}

const mapStateToProps = (state: any) => {
    const popupResult = state.popupResult;
    return { popupResult, };
};

export default connect(mapStateToProps, {
    handlePopup
})(FHShuttleTable);
